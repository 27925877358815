const editingProductDictES = {
  removeImage: "Eliminar Imagen",
  editProduct: "Editar producto",
  productEditedSuccessfully: "Producto editado con éxito!",
  thisProductHasVariationsEdit:
    "Este producto tiene variaciones. Es necesario editarlo individualmente para establecer el precio, el stock y la imagen de variación.",
  clear: "Limpiar",
  technicalInfoDeletedSuccessfully: "Información técnica eliminada con éxito.",
  thumbnailExplanation: "La primera imagen es la portada del producto",
  productDimensions: "Dimensiones del producto",
  dimensionsGreaterThanZero: "Las dimensiones del producto deben ser mayores que cero.",
  dimensionsGreaterThanOne: "Las dimensiones del producto deben ser mayores o iguales a 10.",
  dimensionsJust2DecimalPlaces: "Las dimensiones del producto deben tener solo 2 decimales.",
  category: "Categoría",
  subcategory: "Subcategoría",
  pleaseUploadDownloadable: "Por favor, suba un archivo para el producto.",
  pleaseUploadSample: "Por favor, suba un archivo de muestra para el producto.",
  pleaseFillModel: "Por favor, complete el modelo del producto.",
  actualPrice: "Precio actual",
  banggoodPrice: "Precio banggood",
  newPrice: "Nuevo precio",
  priceMustBeMoreThanPriceFreight: "El precio debe ser mayor que el precio del flete.",
};

export default editingProductDictES;
