const landingDictPT = {
  home: "Início",
  benefits: "Benefícios",
  benefitsExplanation: "Nossa plataforma é uma ferramenta moderna, de fácil acesso e lucrativa.",
  firstBenefit: "Maior integração comercial",
  secondBenefit: "Economia mais leve e potente",
  thirdBenefit: "Oportunidade de lucro instantânea e consistente",
  fourthBenefit: "Gere crédito",
  features: "Recursos",
  howItWorks: "Como funciona",
  meetOurPlans: "Conheça nossos planos",
  join: "Nossos Planos",
  plans: "Planos",
  login: "Entrar",
  whatIsIt: "O marketplace que te recompensa sempre",
  whatIsItExplanation:
    "A Extraconomy é uma plataforma online de economia cooperativa com um sistema de recompensas e geolocalização que permite a particulares e empresas aumentar o seu poder económico e a sua rede de clientes de forma automática e progressiva, através das diversas atividades realizadas no mercado.",
  why: "Por que?",
  greaterBusiness: "Maior integração comercial",
  lighterPowerful: "Economia mais leve e potente",
  instantProfit: "Oportunidade de lucro instantânea e consistente",
  genCredit: "Gere crédito",
  whyShould: "Por que devo me juntar à Extraconomy?",
  seeBelow: "Veja abaixo os recursos da nossa rede social e econômica:",
  extracredit: "Extracredit",
  extracoinExplanation:
    "Registre-se e ganhe Extracredit (consulte as condições). Use o crédito para comprar e/ou compartilhar com seus amigos.",
  indicate: "Ganhe Créditos Extracredit",
  youMayGain: "Indique usuários para sua rede e ganhe créditos Extracredit e dinheiro real.",
  economicNetwork: "Rede Econômica",
  networkOfUsers: "Construa rede de usuários para comprar e vender produtos e serviços.",
  transactionSafety: "Segurança nas Transações",
  ourPlatform:
    "Nossa plataforma é robusta e segura. Você pode comprar, vender e transferir dinheiro com segurança.",
  findEverything: "Encontre Tudo que Precisa",
  weHavePowerfulTool:
    "Temos uma ferramenta poderosa para encontrar o produto que você deseja adquirir em poucos cliques.",
  buyerReviews: "Avaliações de Compradores",
  checkTheReviews:
    "Verifique as avaliações de usuários que compraram o produto que você está procurando.",
  geolocation: "Geolocalização",
  effectiveTools: "Ferramentas eficazes para aumentar automaticamente sua rede econômica.",
  exceptionalTrading: "Negócios Excepcionais",
  saferMoreFrequent: "Trocas mais seguras, frequentes e vantajosas.",
  economicInfluence: "Influência Econômica",
  sellFaster: "Realize vendas mais rápido, compre com mais facilidade, sempre lucrando.",
  howItWorksExplanation:
    "Você recebe recompensas por cada atividade que acontecer na plataforma, na forma de dinheiro e créditos Extracredit que podem ser utilizados dentro do sistema como complemento econômico nas trocas comerciais.",
  contactWhatTele:
    "Para informações sobre o projeto e sobre a abertura do mercado para Partners e Managers em um País, escreva para os seguintes contatos de Whatsapp e Telegram.",
  joinExtraconomy: "Junte-se à Extraconomy.",
  belowArePlans: "Abaixo estão os tipos de planos disponíveis em nosso serviço.",
  learnMore: "Saiba mais",
  doubts: "Dúvidas? ",
  contactToUs: "Entre em contato conosco.",
  contact: "Contato",
  scanQRCode: "Escaneie o QR Code abaixo para nos contactar via WhatsApp ou Telegram:",
  highlights: "Destaques",
  by: " por ",
  video: "Vídeo",
  introVideo: "Vídeo de introdução sobre nossa plataforma:",
  backToHomePage: "Voltar à Página de Início",
  qrCodeExplanation:
    "Para informações sobre o projeto e sobre a abertura do mercado para Partners e Managers em um País, escreva para os seguintes contatos de Whatsapp e Telegram:",
  socialMedia: "Redes Sociais",
  rightsReserved: "Todos os Direitos Reservados",
  checkApp: "Confira nosso aplicativo",
  customerDescription: "Só para compras",
  partnerDescription: "Para vendas e compras",
  managerDescription: "E-commerce + Compras",
  benefitsExtracoinSection: "Benefícios na compra de Extracoin",
  extracoinBenefitTitle1: "2.000 Extracoins: Torne-se um Partner na Extraconomy!",
  extracoinBenefitDescription1:
    "Holders de 2.000 Extracoins recebem um voucher gratuito para se tornarem Partners no Marketplace da Extraconomy. Junte-se à revolução e participe ativamente na formação do futuro da economia digital.",
  extracoinBenefitTitle2: "5.000 Extracoins: Desbloqueie o Status de Seller na Extraconomy!",
  extracoinBenefitDescription2:
    "Holders de 5.000 Extracoins são recompensados com um voucher gratuito para se tornarem Sellers na plataforma Extraconomy. Mostre seus produtos e serviços para uma audiência global.",
  extracoinBenefitTitle3: "15.000 Extracoins: Alcance o Status de Manager na Extraconomy!",
  extracoinBenefitDescription3:
    "Holders de 15.000 Extracoins recebem um voucher exclusivo para se tornarem Managers na Extraconomy. Mergulhe no universo gerencial e contribua para o crescimento de todo o ecossistema.",
  voucherExtracoinText1: "Já tem um ",
  clickHere: "Clique aqui",
  andRegister: "e faça seu registro",
  voucherEmptyError: "O campo do voucher não pode estar vazio.",
  extracoinVoucherError: "O voucher inserido não é válido.",
  startsWith: "Começar com",
  contains: "Contém",
  notContains: "Não Contém",
  endsWith: "Termina com",
  equals: "É igual a",
  notEquals: "Não é igual",
  noFilter: "Sem filtro",
  joinTheLaunchpad: "Junte-se ao Launchpad do nosso token Extracoin no Cryptosmart!",
  popupDesc:
    "Ao adquirir tokens EXC a um preço especial, você receberá em troca um voucher grátis para abrir uma loja online no marketplace Extraconomy, ativando sua renda diária!",
  exclusiveBenefits: "Benefícios Exclusivos",
  popupPartnerTitle: "Torne-se um Partner!",
  popupPartnerDesc:
    "*Ao comprar um mínimo de 2.000 Extracoin você receberá um voucher grátis para abrir uma vetrine de vendas no marketplace Extraconomy.",
  popupSellerTitle: "Desbloqueie o perfil de Seller!",
  popupSellerDesc:
    "*Ao adquirir no mínimo 5.000 Extracoin você receberá um voucher grátis para abrir um e-commerce na plataforma Extraconomy.",
  popupManagerTitle: "Alcance o status de Manager!",
  popupManagerDesc:
    "*Ao comprar um mínimo de 15.000 Extracoin você receberá um voucher exclusivo para abrir uma loja online na Extraconomy com todos os benefícios incluídos.",
  promotionValidUntilEndLaunchpad: "Promoção válida até o final do Launchpad",
  downloadAppAndEarn: "Baixe o aplicativo agora e ganhe 20 euros de créditos imediatamente",
  frequentlyAskedQuestion: "Perguntas Frequentes",
  faqTitle1: "A plataforma Extraconomia é segura?",
  faqAnswer1:
    "Sim, contamos com uma equipe técnica especializada na segurança das informações. Todos os conteúdos, dados e operações financeiras na plataforma estão protegidos contra vários níveis de segurança, para que possamos mitigar o máximo possível de qualquer tipo de ataque informático que possa ser verificado.",
  faqTitle2: "Você quer adquirir a Extraconomy, como posso participar da plataforma?",
  faqAnswer2:
    "Se você descobriu a Extraconomy por meio de alguém, essa pessoa sabe como colocá-lo na plataforma. Caso contrário, baixe nosso App e cadastre-se inserindo seus dados para acessar diretamente a Loja. Você receberá um crédito de boas-vindas para usar em suas compras futuras!",
  faqTitle3: "Existem taxas de registro para poder vender meus produtos ou serviços?",
  faqAnswer3:
    "Sim, para ter um espaço para a sua montra ou para o seu e-commerce existem 3 planos de vendas com valores e vantagens diferentes dependendo das necessidades de cada utilizador particular e empresarial. Você pode descobrir os Planos diretamente aqui neste site.",
  faqTitle4:
    "Quando realizo atividades promocionais e de vendas na plataforma Extraconomy, tenho vínculo empregatício?",
  faqAnswer4:
    "Não, se você aderiu a um dos 3 planos de vendas você será autônomo e será totalmente responsável por suas despesas. Somente se decidir trabalhar conosco como Promotor você receberá um contrato de Representante de Vendas.",
  faqTitle5: "Como posso solicitar a exclusão da minha conta?",
  faqAnswer5:
    "Para cancelar a sua conta Extraconomy basta fazer login na sua Área Pessoal e depois em Editar Perfil solicitar o cancelamento da conta sem custos adicionais. Ou você pode enviar um email para support@extraconomyglobal.com usando o mesmo email que você usa para acessar o Extraconomy, com o assunto “Cancelamento de conta”.",
  faqTitle6: "Sou novo na plataforma, há algum suporte?",
  faqAnswer6:
    "Sim, temos suporte humanizado e dedicado para te ajudar sempre que precisar através dos canais Whatsapp e Telegram que você encontra entre nossos contatos aqui no site.",
  faqTitle7: "Forneci o e-mail errado, e agora?",
  faqAnswer7:
    "Para alterar seu e-mail, você precisará enviar um e-mail para support@extraconomyglobal.com com o assunto “Alterar e-mail”, informando todos os dados cadastrais utilizados no cadastro e o e-mail correto.",
  faqTitle8: "Quais são as opções de pagamento?",
  faqAnswer8:
    "Através do PayPal, nosso gateway de pagamento internacional, você pode pagar seu pedido com cartão de crédito, cartão de débito ou conta bancária.",
  faqTitle9: "Quanto custa o frete para minha região?",
  faqAnswer9:
    "Para esse cálculo, a maneira mais fácil é simular seu pedido. Escolha os itens com Frete Pago, adicione-os ao carrinho e finalize a compra, insira seus dados corretamente (muito importante) e ao final do pedido o site mostrará o valor correto do frete. Se você está apenas simulando, não clique em Finalizar Compra e isso não resultará em nenhuma compra real de sua parte. Depois edite os itens e quantidades para ver as diferenças, fique à vontade.",
  faqTitle10: "Como funcionam os créditos adquiridos na Extraconomia?",
  faqAnswer10:
    "Os créditos internos são adquiridos através das diversas atividades realizadas na plataforma (Registos, Compra-Venda e Partilha) sob a forma de cashbacks, prémios e bónus, que podem ser utilizados de forma complementar em compras dentro da plataforma ou em atividades de marketing baseadas nos termos de uso fornecidos para cada perfil de usuário. ",
  faqTitle11:
    "Como posso monitorar e gerenciar meus pagamentos, comissões e bônus recebidos na Extraconomy?",
  faqAnswer11:
    "Ao se cadastrar pela Web e pelo App, você recebe em sua Área Pessoal um escritório virtual onde poderá acompanhar todas as suas receitas financeiras diretas e provenientes do seu círculo econômico.",
  faqTitle12: "Como recebo meus rendimentos financeiros? ",
  faqAnswer12:
    "Os rendimentos económicos diretos e indiretos são creditados na sua conta PayPal, que deverá inserir na sua Área Pessoal. Se você não possui uma conta PayPal, pode criar uma gratuitamente em www.paypal.com.",
  SeeTheRegulationsHere: "Veja aqui o regulamento",
  joinTheLaunchpadButton: "Conheca o regulamento do Launchpad",
  tax: "Taxa",
  workWithUs: "Trabalhe Conosco",
  jobOpportunity: "Oportunidade de emprego",
  workWithUsText1:
    "O sistema Extraconomy oferece a possibilidade de participar no desenvolvimento online e territorial através de figuras-chave (promotor web, embaixador, agente comercial, microinfluenciador, afiliados de mercado) que beneficiarão automaticamente de um sistema de comissões exclusivo, escalável e gratificante.",
    freePlan: "Gratuito",
    salesRepresentativeContract: "Contrato de Representante de Vendas",
    earnCommissionsAndCredits: "Ganhe comissões e créditos",
    developYourNetwork: "Desenvolva sua rede",
    financialCareerAwards: "Prêmios de Carreira Financeira",
};

export default landingDictPT;
