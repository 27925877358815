import { createContext, useContext, useEffect, useMemo, useState } from "react";
import GetUserInfoService from "../../service/GetUserInfoService";
import { useToastContext } from "../ToastContext/ToastContext";

export const UserContext = createContext(null);

const initialState = null;

export function UserProvider(props) {
  const [userInfo, setUserInfo] = useState(initialState);
  const { showErrorToast } = useToastContext();

  const userInfoService = new GetUserInfoService();

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const res = await userInfoService.getUserInfo(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        );

        if (res.messageCode === 200001) setUserInfo(res);
        else {
          showErrorToast({ code: res.messageCode });
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (
      localStorage.getItem("__extraconomy_token") &&
      localStorage.getItem("__extraconomy_username")
    )
      loadUserInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      userInfo,
      setUserInfo,
    }),
    [setUserInfo, userInfo]
  );

  return <UserContext.Provider value={value} {...props} />;
}

export function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a AppProvider");
  }

  return context;
}
