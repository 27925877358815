const footerDictEN = {
  connectAndStay: "Connect and stay on top of the news",
  moreInfo:
    "For information about the project and about opening the market to Partners and Managers in a country, write to the following contacts:",
  shippingMethodFooter: "Shipping method",
  partnersShip: "Partners",
  distance: "Distance",
  allallTextDistance: "All",
  fillAllFields: "Fill in all fields",
  searchStoreNearByYou: "Search for the stores closest to you",
};

export default footerDictEN;
