const footerDictIT = {
  connectAndStay: "Connettiti e rimani aggiornato sulle notizie",
  moreInfo:
    "Per maggiori informazioni sul progetto e sullo sviluppo territoriale, scrivere ad uno dei seguenti contatti qui sotto:",
  shippingMethodFooter: "Metodo di spedizione",
  partnersShip: "Partners",
  distance: "Distanza",
  allText: "Tutti",
  fillAllFields: "Compila tutti i campi",
  searchStoreNearByYou: "Cerca i negozi più vicini a te",
};

export default footerDictIT;
