const adminBackofficeDictIT = {
  adverts: "Annunci",
  users: "Utenti",
  orders: "Ordini",
  paidSubscriptions: "Abbonamenti a pagamento",
  paymentMethod: "Metodo di pagamento",
  initialSub: "Registrazione iniziale",
  annualRenewal: "Rinnovo annuale",
  test: "test",
  adminDashboard: "Pannello di amministrazione",
  withdrawRequests: "Richieste di prelievo",
  requestDate: "Data richiesta",
  processDate: "Data di elaborazione",
  processing: "In lavorazione",
  amountToDeposit: "Ammontare da depositare",
  amountRequested: "Importo richiesto",
  processRequest: "Processi la richiesta",
  process: "Processi",
  processedBy: "Elaborato da",
  withdrawProcessedSuccessfully: "Richiesta di prelievo elaborata con successo!",
  blockOrUnblockUser: "Blocca/Sblocca utente",
  block: "Blocca",
  unblock: "Sblocca",
  user: "Utente",
  processThisRequest: "Elaborare questa richiesta?",
  userBlockedSuccessfully: "Utente bloccato con successo.",
  userUnblockedSuccessfully: "Utente sbloccato con successo!",
  areYouSureBlockUser: "Sei sicuro di voler bloccare questo utente?",
  areYouSureUnblockUser: "Sei sicuro di voler sbloccare questo utente?",
  blocked: "Bloccato",
  yes: "Sì",
  no: "No",
  category: "Categoria",
  searchCategory: "Cerca Categoria",
  categoriesRegistered: "Categorie registrate",
  titleCategory: "Título",
  image: "Immagine",
  descriptionPT: "Descrizione portoghese",
  descriptionIT: "Descrizione italiana",
  descriptionES: "Descrizione spagnola",
  descriptionEN: "Descrizione inglese",
  registerNewCategory: "registra nuova categoria",
  addNewCategory: "Aggiungi nuova categoria",
  newCategory: "Nuovo",
  categoryInfo: "Informazioni sulla categoria",
  categoryFields: "Compila i campi con le informazioni sulla categoria.",
  categoryImage: "Immagine categoria",
  dragImg: "Trascina qui la tua immagine",
  registerCategory: "Registrati",
  descriptionBrand: "Descrizione del marchio",
  brandsRegistered: "Marchi",
  brands: "Marchi",
  addNewBrand: "Aggiungi nuovo marchio",
  brandInfo: "Informazioni sul marchio",
  brandFields: "Compila il campo con le informazioni sul marchio.",
  registerBrand: "Register",
  newBrand: "Nuovo",
  typesCategoriesRegistered: "Tipi di categorie registrati",
  descriptionType: "Descrizione",
  descriptionTypeEN: "Descrizione inglese",
  descriptionTypeES: "Descrizione spagnola",
  descriptionTypeIT: "Descrizione italiana",
  descriptionTypePT: "Descrizione portoghese",
  types: "Tipi",
  addNewType: "Aggiungi nuovi tipi",
  typeInfo: "Informazioni sul tipo",
  typeFields: "Compila i campi con le informazioni sul tipo.",
  newType: "Nuovo",
  registerType: "Register",
  subcategory: "Sottocategorie",
  newsubcategory: "Nuovo",
  subcategoriesRegistered: "Sottocategorie registrate",
  descriptionSubcategoryEN: "Descrizione inglese",
  descriptionSubcategoryIT: "Descrizione italiana",
  descriptionSubcategoryPT: "Descrizione portoghese",
  descriptionSubcategoryES: "Descrizione in spagnolo",
  service: "servizio",
  addNewSubcategory: "Aggiungi nuova sottocategoria",
  subcategoryInfo: "Informazioni sulla sottocategoria",
  subcategoryFields: "Compila i campi con le informazioni sulla sottocategoria.",
  subcategoryImage: "Immagine sottocategoria",
  registerSubcategory: "Registrati",
  chooseSubcategory: "Scegli almeno una sottocategoria",
  address: "Indirizzo",
  userType: "tipologia di utente",
  chooseUser: "scegli un utente",
  chooseAddress: "scegli un indirizzo",
  UserAddressOnSendCloud: "L'indirizzo dell'utente era registrato in sendcloud?",
  searchUser: "Cerca utente.",
  userInfo: "Informazioni utente",
  adminBackofficeName: "Nome",
  hasNoAttributes: "Non ha",
  adminBackofficeUserType: "Tipologia di utente",
  adminBackofficeCountry: "Paese",
  adminBackofficeAddress: "Indirizzo",
  adminBackofficeCity: "Città",
  adminBackofficeState: "Stato",
  adminBackofficeDistrict: "Distretto",
  adminBackofficePostalCode: "Codice postale",
  adminBackofficeNumber: "Numero",
  adminBackofficeComplement: "Complemento",
  adminBackofficeDateCreation: "Data di creazione",
  adminBackofficePhoneNumber: "Telefono",
  adminBackofficeOriginRegister: "Origine registrazione",
  searchByEmail: "ricerca tramite e-mail",
  searchByFirstname: "ricerca per nome",
  searchByPhonenumber: "Cerca per numero di telefono",
  searchByReferralName: "Cerca per referenza",
  searchByBrand: "cerca per marca",
  usersOfSystem: "Utenti del sistema",
  productsNotPublished: "Prodotti non pubblicati",
  archivedProducts: "Prodotti archiviati",
  productsPublished: "Prodotti pubblicati",
  salesRevenue: "Ricavi delle vendite",
  feeRevenue: "Ricavi delle commissioni",
  registeredStores: "Negozi registrati",
  completedOrders: "Ordini completati",
  revenueFromUserRegistration: "Ricavi dalla registrazione degli utenti",
  productsOfSystem: "Prodotti del sistema",
  filters: "Filtri",
  search: "Cerca",
  searchByNameSurnameUserAccountBank: "Cerca per nome, cognome, utente, conto bancario",
  ordersPlacedWithBangood: "Ordini effettuati con Bangood",
  actions: "Azioni",
  processOrderSuccessfully: "Ordine elaborato con successo!",
};

export default adminBackofficeDictIT;
