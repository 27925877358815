const adminBackofficeDictPT = {
  adverts: "Anúncios",
  users: "Usuários",
  orders: "Pedidos",
  paidSubscriptions: "Inscrições Pagas",
  paymentMethod: "Método de pagamento",
  initialSub: "Inscrição Inicial",
  annualRenewal: "Renovação Anual",
  test: "teste",
  adminDashboard: "Painel Administrativo",
  withdrawRequests: "Solicitações de saque",
  requestDate: "Data da solicitação",
  processDate: "Data do processamento",
  processing: "Processando",
  amountToDeposit: "Valor para depositar",
  amountRequested: "Valor solicitado",
  processRequest: "Processar solicitação",
  process: "Processar",
  processedBy: "Processado por",
  withdrawProcessedSuccessfully: "Solicitação de saque processada com sucesso!",
  blockOrUnblockUser: "Bloquear/Desbloquear usuário",
  block: "Bloquear",
  unblock: "Desbloquear",
  user: "Usuário",
  processThisRequest: "Processar essa solicitação?",
  userBlockedSuccessfully: "Usuário bloqueado com sucesso.",
  userUnblockedSuccessfully: "Usuário desbloqueado com sucesso!",
  areYouSureBlockUser: "Tem certeza de que deseja bloquear este usuário?",
  areYouSureUnblockUser: "Tem certeza de que deseja desbloquear este usuário?",
  blocked: "Bloqueado",
  yes: "Sim",
  no: "Não",
  category: "Categorias",
  searchCategory: "Buscar Categoria",
  categoriesRegistered: "Categorias Cadastradas",
  titleCategory: "Título",
  image: "Imagem",
  descriptionPT: "Descrição português",
  descriptionIT: "Descrição italiano",
  descriptionES: "Descrição espanhol",
  descriptionEN: "Descrição inglês",
  registerNewCategory: "Cadastrar novas categoria",
  addNewCategory: "Adicionar Nova Categoria",
  newCategory: "Novo",
  categoryInfo: "Informações da categoria",
  categoryFields: "Preencha os campos com as informações da categoria.",
  categoryImage: "Imagem da categoria",
  dragImg: "Arraste sua imagem aqui",
  registerCategory: "Cadastrar",
  descriptionBrand: "Descrição marca",
  brandsRegistered: "Marcas Registradas",
  brands: "Marcas",
  addNewBrand: "Adicionar nova marca",
  brandInfo: "Informações da marca",
  brandFields: "Preencha o campo com as informações da marca.",
  registerBrand: "Registrar",
  newBrand: "Novo",
  typesCategoriesRegistered: "Tipos Categorias Registradas",
  descriptionType: "Descrição",
  descriptionTypeEN: "Descrição inglês",
  descriptionTypeES: "Descrição espanhol",
  descriptionTypeIT: "Descrição italiano",
  descriptionTypePT: "Descrição português",
  types: "Tipos",
  addNewType: "Adicionar novos tipos",
  typeInfo: "Informações do tipo",
  typeFields: "Preencha os campos com as informações do tipo.",
  newType: "Novo",
  registerType: "Registrar",
  subcategory: "Subcategorias",
  newsubcategory: "Novo",
  subcategoriesRegistered: "Subcategorias Registradas",
  descriptionSubcategoryEN: "Descrição inglês",
  descriptionSubcategoryIT: "Descrição italiano",
  descriptionSubcategoryPT: "Descrição português",
  descriptionSubcategoryES: "Descrição espanhol",
  service: "serviço",
  addNewSubcategory: "Adicionar Nova Subcategoria",
  subcategoryInfo: "Informações da subcategoria",
  subcategoryFields: "Preencha os campos com as informações da subcategoria.",
  subcategoryImage: "Imagem da subcategoria",
  registerSubcategory: "Cadastrar",
  chooseSubcategory: "Escolha pelo menos uma subcategoria",
  address: "Endereço",
  userType: "Tipo de usuário",
  chooseUser: "escolha um usuário",
  chooseAddress: "Escolha um endereço",
  UserAddressOnSendCloud: "O Endereço do usuário foi cadastrado no sendcloud?",
  searchUser: "Pesquisar usuário.",
  userInfo: "Informações do usuário",
  adminBackofficeName: "Nome",
  hasNoAttribute: "Não possui",
  adminBackofficeUserType: "Tipo de usuário",
  adminBackofficeCountry: "País",
  adminBackofficeAddress: "Endereço",
  adminBackofficeCity: "Cidade",
  adminBackofficeState: "Estado",
  adminBackofficeDistrict: "Bairro",
  adminBackofficePostalCode: "CEP",
  adminBackofficeNumber: "Número",
  adminBackofficeComplement: "Complemento",
  adminBackofficeDateCreation: "Data de criação",
  adminBackofficePhoneNumber: "Telefone",
  adminBackofficeOriginRegister: "Origem do cadastro",
  searchByEmail: "Buscar por email",
  searchByFirstname: "Buscar por  nome",
  searchByPhonenumber: "Buscar por  número de telefone",
  searchByReferralName: "Buscar por referidor",
  searchByBrand: "Buscar por marca",
  usersOfSystem: "Usuários do sistema",
  productsNotPublished: "Produtos não publicados",
  archivedProducts: "Produtos arquivados",
  productsPublished: "Produtos publicados",
  salesRevenue: "Receita de vendas",
  feeRevenue: "Receita de taxas",
  registeredStores: "Lojas registradas",
  completedOrders: "Pedidos finalizados",
  revenueFromUserRegistration: "Receita com cadastro de usuários",
  productsOfSystem: "Produtos do sistema",
  filters: "Filtros",
  search: "Pesquisar",
  searchByNameSurnameUserAccountBank: "Pesquisar por nome, sobrenome, usuário, conta bancária",
  ordersPlacedWithBangood: "Pedidos realizados com Bangood",
  actions: "Ações",
  processOrderSuccessfully: "Pedido processado com sucesso!",
};

export default adminBackofficeDictPT;
