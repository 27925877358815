import React, { useEffect, useState } from "react";

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "./styles.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GetUserInfoService from "../../service/GetUserInfoService";
import localeText from "../../locale";
import { Button } from "primereact/button";
import { useUserContext } from "../../contexts/UserContext/UserContext";
import L from "leaflet";
import PinPoint from "./pinpoint.png";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryInstance } from "../../utilities/Cloudinary/cloudinaryInstance";
import { fill } from "@cloudinary/url-gen/actions/resize";

const markerIcon = new L.Icon({
  iconUrl: PinPoint,
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const ChangeMapCenter = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};

const MapLeaflet = ({ style }) => {
  const { userInfo } = useUserContext();
  const { showErrorToast } = useToastContext();
  const [loadingStores, setLoadingStores] = useState(false);
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const [center, setCenter] = useState({ lat: 41.9, lng: 12.5 });
  const ZOOM_LEVEL = 9;
  const [cep, setCep] = useState("");
  const [selectedDistance, setSelectedDistance] = useState(null);
  const [stores, setStores] = useState([]);

  const distances = [
    { name: "5Km", distance: 5 },
    { name: "10Km", distance: 10 },
    { name: "20Km", distance: 20 },
    { name: "50Km", distance: 50 },
    { name: "100Km", distance: 100 },
    { name: localeText("allText"), distance: 999999999999999 },
  ];

  const userInfoService = new GetUserInfoService();

  const handleChangeCep = async (e) => {
    setCep(e?.target?.value?.replace(/\D/g, ""));
  };

  const searchStores = async () => {
    try {
      if (!cep || !selectedDistance) {
        showErrorToast({ detail: "fillAllFields" });
      }
      setLoadingStores(true);
      await searchMyCep(cep);
      const res = await userInfoService.searchShopsLocation({
        myCep: cep,
        distance: selectedDistance?.distance,
      });
      setStores(res.result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingStores(false);
    }
  };

  const searchMyCep = async (cep) => {
    try {
      setLoadingMyLocation(true);
      const res = await userInfoService.searchMyLocation({
        myCep: cep,
      });
      setCenter({ lat: res?.result?.latitude, lng: res?.result?.longitude });
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMyLocation(false);
    }
  };

  useEffect(() => {
    if (userInfo?.result?.user?.postalCode) {
      setCep(userInfo?.result?.user?.postalCode);
      searchMyCep(userInfo?.result?.user?.postalCode);
    }
  }, [userInfo?.result?.user?.postalCode]);

  return (
    <div>
      <div className="flex justify-content-center align-items-center">
        <MapContainer style={style} center={center} zoom={ZOOM_LEVEL} scrollWheelZoom={false}>
          <ChangeMapCenter center={center} />

          <div className="map-search-stores">
            <InputText
              value={cep}
              onChange={handleChangeCep}
              placeholder={localeText("zipCode")}
              name="cep"
              style={{ width: "80px" }}
            />
            <Dropdown
              value={selectedDistance}
              onChange={(e) => setSelectedDistance(e.value)}
              options={distances}
              optionLabel="name"
              placeholder={localeText("distance")}
              className="w-full md:w-14rem"
              style={{ width: "80px" }}
            />
            <Button
              onClick={searchStores}
              size="small"
              icon="pi pi-search"
              disabled={loadingStores || loadingMyLocation}
              loading={loadingStores || loadingMyLocation}
            />
          </div>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {stores &&
            stores?.length > 0 &&
            stores.map((store) => (
              <Marker
                icon={markerIcon}
                position={[store?.latitude, store?.longitude]}
                style={{ zIndex: 999 }}
              >
                <Popup>
                  <div>
                    <AdvancedImage
                      cldImg={cloudinaryInstance
                        .image(store?.imagelogoid)
                        .format("webp")
                        .resize(fill())}
                      className="border-1 border-300 w-full"
                    />
                  </div>
                  <div>
                    {store?.title}
                    <div>
                      {store?.facebook && <p>Facebook: {store?.facebook}</p>}
                      {store?.instagram && <p>Instagram: {store?.instagram}</p>}
                      {store?.tiktok && <p>Tiktok: {store?.tiktok}</p>}
                      {store?.twitter && <p>Twitter: {store?.twitter}</p>}
                      {store?.youtube && <p>Youtube: {store?.youtube}</p>}
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}

          <Marker icon={markerIcon} position={[-5.782483, -35.248357]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default MapLeaflet;
