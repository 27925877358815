import { fill } from "@cloudinary/url-gen/actions/resize";

const footerDictPT = {
  connectAndStay: "Conecte-se e fique por dentro das novidades",
  moreInfo:
    "Para informações sobre o projeto e sobre a abertura do mercado para Partners e Managers em um País, entre em contato conosco pelas seguintes redes sociais:",
  shippingMethodFooter: "Forma de envio",
  partnersShip: "Parceiros",
  distance: "Distância",
  allText: "Todos",
  fillAllFields: "Preencha todos os campos",
  searchStoreNearByYou: "Busque as lojas mais próximas de você",
};

export default footerDictPT;
