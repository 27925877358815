import { Link } from "react-router-dom";
import localeText from "../../locale";
import Wrapper from "../Wrapper/Wrapper";
import { WhatsappLogo } from "../../pages/images/landing/svg/whatsapp-logo";
import { TelegramLogo } from "../../pages/images/landing/svg/telegram-logo";
//import { FacebookLogo } from "../../pages/images/landing/svg/facebook-logo";
import logoWhite from "../../pages/images/logo-white-notext.png";
import ExternalLinkIcon from "../ExternalLinkIcon/ExternalLinkIcon";
import "./Footer.css";
import { footerLinks } from "./constants";
import cryptosmart from "../../pages/images/parcerias/cryptosmart.jpg";
import fondoSanMarco from "../../pages/images/parcerias/fondoSanMarco.jpg";
import pikoSolution from "../../pages/images/parcerias/pikoSolution.jpg";
import grupo8 from "../../pages/images/parcerias/grupoG8.jpg";
import logoIospedisco from "../../pages/images/logo-iospedisco.png";
import paypalImg from "../../pages/images/paypal.png";
import masterCardImg from "../../pages/images/masterCard.png";
import americanExpress from "../../pages/images/expressoAmericano.png";
import visa from "../../pages/images/visa.png";
import PagamentoSeguro from "../../pages/images/pagamentoSeguro.png";
import "leaflet/dist/leaflet.css";
import MapLeaflet from "../Map/index";

const Footer = () => {
  return (
    <>
      <div className="bg-green-600 w-full pt-8">
        <h1 className="text-4xl lg:text-5xl font-bold text-white text-center mb-5" style={{ maxWidth: "20ch", margin: "0 auto" }}>
          {localeText("searchStoreNearByYou")}
        </h1>
        <MapLeaflet />
      </div>

      <div id="QrCode" className="bg-green-600 w-full">
        <Wrapper classnames="bg-green-600 flex py-5 lg:py-6 align-items-center flex-column justify-content-center w-full pb-8 text-center px-2">
          <h1 className="text-4xl lg:text-5xl font-bold text-white" style={{ maxWidth: "20ch" }}>
            {localeText("connectAndStay")}
          </h1>
          <p className="text-white text-lg lg:text-2xl lg:mb-6" style={{ maxWidth: "50ch" }}>
            {localeText("moreInfo")}
          </p>
          <div className="flex justify-content-center">
            <div className="flex justify-content-center xl:gap-4 gap-3 align-items-center lg:flex-row flex-column">
              <a
                target="_blank"
                className="w-15rem h-15rem bg-white hover:bg-green-600 flex flex-column justify-content-center align-items-center gap-2 p-2 text-green-600 hover:text-white border-round transition-all transition-duration-300"
                href="https://wa.me/message/AALEU6LSOPNWM1"
                rel="noreferrer"
              >
                <WhatsappLogo />
                Whatsapp
              </a>
              <a
                target="_blank"
                className="w-15rem h-15rem bg-white hover:bg-green-600 flex flex-column justify-content-center align-items-center gap-2 p-2 text-green-600 hover:text-white border-round transition-all transition-duration-300"
                href="https://t.me/+ioCTpVD9LmhhZTc0"
                rel="noreferrer"
              >
                <TelegramLogo />
                Telegram
              </a>
              {/* <a
                target="_blank"
                className="w-15rem h-15rem bg-white hover:bg-green-600 flex flex-column justify-content-center align-items-center gap-2 p-2 text-green-600 hover:text-white border-round transition-all transition-duration-300"
                href="https://www.facebook.com/profile.php?id=61566025845885"
                rel="noreferrer"
              >
                <FacebookLogo />
                Facebook
              </a> */}
            </div>
          </div>
        </Wrapper>
      </div>
      <div id="QrCode" className="bg-green-600 w-full">
        <Wrapper classnames="bg-green-600 flex py-7 lg:py-8 align-items-center flex-column justify-content-center w-full pb-8 text-center px-2">
          <div className="mb-5">
            <h1 className="text-2xl lg:text-3xl font-bold text-white" style={{ maxWidth: "20ch" }}>
              {localeText("paymentMethods")}
            </h1>

            <div className="flex mt-4 justify-content-center align-items-center w-full flex-wrap gap-2 mx-auto">
              <img
                src={paypalImg}
                alt="paypal"
                title="paypal"
                style={{ width: "20px", height: "20px" }}
              />
              <img
                src={americanExpress}
                alt="americanExpress"
                title="americanExpress"
                style={{ width: "20px", height: "20px" }}
              />
              <img
                src={masterCardImg}
                alt="mastercard"
                title="mastercard"
                style={{ width: "30px", height: "20px" }}
              />
              <img src={visa} alt="visa" style={{ width: "35px", height: "10px" }} title="visa" />
            </div>
            <div className="flex justify-content-center align-items-center gap-2 mt-3">
              <p className="m-0 text-xs text-white">{localeText("safePayment")}</p>
              <img
                src={PagamentoSeguro}
                alt=""
                style={{ width: "20px" }}
                title={localeText("safePayment")}
              />
            </div>
          </div>

          <h1
            className="text-2xl lg:text-3xl font-bold text-white mb-5"
            style={{ maxWidth: "20ch" }}
          >
            {localeText("shippingMethodFooter")}
          </h1>

          <div className="flex justify-content-center align-items-center">
            <img
              src={logoIospedisco}
              alt="logo do iospedisco"
              width={"120px"}
              className="bg-white border-round p-2"
            />
          </div>

          <div className="mt-5 mb-4">
            <h1
              className="text-2xl lg:text-3xl font-bold text-white mb-5"
              style={{ maxWidth: "20ch", margin: "0 auto" }}
            >
              {localeText("partnersShip")}
            </h1>

            <div className="flex gap-2 flex-wrap justify-content-center">
              <img
                src={cryptosmart}
                alt="logo do iospedisco"
                style={{ objectFit: "contain" }}
                width={"120px"}
                height={"90px"}
                className="bg-white border-round p-2"
              />

              <img
                src={fondoSanMarco}
                style={{ objectFit: "contain" }}
                alt="logo do iospedisco"
                width={"120px"}
                height={"90px"}
                className="bg-white border-round p-2"
              />

              <img
                src={pikoSolution}
                style={{ objectFit: "contain" }}
                alt="logo do iospedisco"
                width={"120px"}
                height={"90px"}
                className="bg-white border-round p-2"
              />

              <img
                src={grupo8}
                style={{ objectFit: "contain" }}
                alt="logo do iospedisco"
                width={"120px"}
                height={"90px"}
                className="bg-white border-round p-2"
              />
            </div>
          </div>
        </Wrapper>
      </div>
      <div id="footer" style={{ backgroundColor: "#252529" }}>
        <Wrapper>
          <div className="grid">
            <div className="footer-menu w-full">
              <div className="flex justify-content-between lg:flex-row flex-column align-items-center">
                <div className="flex justify-content-center lg:flex-row flex-column mb-4 lg:mb-0 align-items-center gap-4">
                  <img src={logoWhite} alt="white extraconomy logo" style={{ height: "50px" }} />
                  <div className="flex flex-column justify-content-center lg:text-left text-center text-white">
                    <p className="text-white mb-0">
                      © {new Date().getFullYear()} {localeText("rightsReserved")}
                    </p>
                    <p className="mb-0">EXTRACONOMY GLOBAL srl</p>
                    <p className="mb-0">Via Nomentana, 1018 - 00137 Roma(IT)</p>
                  </div>
                  <div className="flex flex-column justify-content-center lg:text-left text-center text-white">
                    <p className="text-white mb-0">Extraconomy Global</p>
                    <p className="mb-0">P. Iva e C.F. 16948681008</p>
                    <p className="mb-0">REA 1686351</p>
                  </div>
                  <div className="flex flex-column h-full justify-content-center xl:align-items-start lg:text-left text-center text-white">
                    <a
                      className="mb-0 text-white hover:text-green-600"
                      href="mailto:info@extraconomyglobal.com"
                    >
                      info@extraconomyglobal.com
                    </a>
                    <Link to="/privacy" className="inline-block text-white hover:text-green-600">
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="flex flex-column h-full justify-content-center xl:align-items-start lg:text-left text-center text-gray-600">
                    <p className="m-0">f:{process.env.REACT_APP_COMMIT_FRONT}</p>
                    <p className="text-gray-600 m-0">b:{process.env.REACT_APP_COMMIT_BACK}</p>
                  </div>
                </div>
                <div className="flex align-items-center gap-3">
                  {footerLinks.map((link, i) => (
                    <ExternalLinkIcon key={i} href={link.href} iconClassNames={link.icon} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default Footer;
