export const footerLinks = [
  {
    href: "https://www.linkedin.com/company/extraconomy/",
    icon: "pi-linkedin",
  },
  {
    href: "https://www.instagram.com/extraconomy_official?igsh=bnQ3YmZ6MDVpZHZz",
    icon: "pi-instagram",
  },
  {
    href: "https://www.facebook.com/profile.php?id=61566025845885",
    icon: "pi-facebook",
  },
  {
    href: "https://www.tiktok.com/@extraconomy?_t=8q7VnsHEI9B&_r=1",
    icon: "pi-tiktok",
  },
  {
    href: "https://x.com/extraconomy?t=epoCHWYeokYWEYL3E5MbbQ&s=08",
    icon: "pi-twitter",
  },
];
