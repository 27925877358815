const footerDictES = {
  connectAndStay: "Conéctese y manténgase al tanto de las noticias",
  moreInfo:
    "Para obtener información sobre el proyecto y sobre la apertura del mercado a Socios y Gerentes en un país, escriba a los siguientes contactos:",
  shippingMethodFooter: "Método de envío",
  partnersShip: "Socios",
  distance: "Distancia",
  allText: "Todos",
  fillAllFields: "Complete todos los campos",
  searchStoreNearByYou: "Busque las tiendas más cercanas a usted",
};

export default footerDictES;
