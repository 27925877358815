const editingProductDictIT = {
  removeImage: "Rimuovi Immagine",
  editProduct: "Modifica prodotto",
  productEditedSuccessfully: "Prodotto modificato con successo!",
  thisProductHasVariationsEdit:
    "Questo prodotto ha delle variazioni. È necessario modificarli individualmente per impostare prezzo, stock e immagini di variazione.",
  clear: "Pulire",
  technicalInfoDeletedSuccessfully: "Informazioni tecniche eliminate con successo.",
  thumbnailExplanation: "La prima immagine è la copertina del prodotto",
  productDimensions: "Dimensioni del prodotto",
  dimensionsGreaterThanZero: "Le dimensioni del prodotto devono essere maggiori di zero.",
  dimensionsGreaterThanOne: "Le dimensioni del prodotto devono essere maggiori o uguali a 10.",
  dimensionsJust2DecimalPlaces: "Le dimensioni del prodotto devono avere solo 2 cifre decimali.",
  category: "Categoria",
  subcategory: "Sottocategoria",
  pleaseUploadDownloadable: "Si prega di caricare un file per il prodotto.",
  pleaseUploadSample: "Si prega di caricare un file di esempio per il prodotto.",
  pleaseFillModel: "Si prega di compilare il modello del prodotto.",
  actualPrice: "Prezzo attuale",
  banggoodPrice: "Prezzo banggood",
  newPrice: "Nuovo prezzo",
  priceMustBeMoreThanPriceFreight: "Il prezzo deve essere maggiore del prezzo del trasporto.",
};

export default editingProductDictIT;
