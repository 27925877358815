const userDashboardDictPT = {
  dashboard: "Painel de Controle",
  userDashboard: "Painel de Controle do Usuário",
  wallet: "Carteira",
  transfer: "Transferir",
  transferRequests: "Solicitações de Transferência",
  productServices: "Produtos e serviços",
  maxBalanceReached: "Você atingiu seu saldo máximo.",
  new: "Novo",
  dropshipping: "Dropshipping",
  list: "Lista",
  sales: "Vendas",
  purchases: "Compras",
  network: "Círculo Econômico",
  directs: "Diretos",
  all: "Todos",
  coupons: "Cupons",
  attributes: "Atributos",
  withdraw: "Saque",
  yourStore: "Sua Loja",
  favorites: "Favoritos",
  isService: "Esta subcategoria é um serviço?",
  importProducts: "Importar Produtos",
  pageNotFound: "Página não encontrada",
  popupHeader1: "Faltam",
  popupHeader2: "dia(s) para expirar sua assinatura",
  subscriptionExpirationDate: "Data de expiração da assinatura",
  wantToRenewSubscription: "Você quer renovar sua assinatura?",
  subscription: "Assinatura",
  renewSubscription: "Renovar Assinatura",
  payWithPaypal: "Pagar com Paypal",
  walletBalancerRenew: "Saldo na carteira",
  paymentMethods: "Formas de pagamento",
  errorRenewingSubscription:
    "Houve um erro ao efetuar a renovação da sua assinatura, tente novamente",
  youWantRenewWalletBalance: "Você deseja renovar com o saldo na carteira?",
  subscriptionRenewed: "Assinatura renovada com sucesso!",
  yourSubcriptionExpired: "Sua assinatura expirou",
  wantToRenewyourSubscription: "Você deseja renová-la?",
  unfavorite: "Desfavoritar",
  waitLabel:
    "Espere sua etiqueta ser gerada. Apenas aperte no botão abaixo quando o seu pedido for pego pela transportadora.",
  freeRenewalsRemaining: "Renovações gratuitas restantes",
  BOZZA: "Aguardando aprovação",
  INSPEDIZIONE: "Em trânsito",
  RITIRORICHIESTO: "Retirada solicitada",
  CONSEGNATA: "Entregue",
  errorDownloadLabel: "Houve um erro ao criar a etiqueta",
  nextAction: "Próximo",
  closeAction: "Fechar",
  backAction: "Voltar",
  welcomeToExtraconomy: "Bem-vindo à Extraconomy",
  ifYouWantToSellOnExtraconomy:
    "Se você deseja vender no extraconomy, então siga os passos para aprender como criar sua loja e seus produtos",
  registerYourAddress: "Registre seu endereço",
  initiallyYouNeedToRegisterYourAddress:
    "Inicialmente você precisa cadastrar seu endereço para que possamos utilizá-lo para as entregas",
  fillInYourContactPhoneNumber: "Preencha seu número de telefone de contato",
  fillInYourContactNumber:
    "Preencha seu telefone de contato para que possamos entrar em contato com você caso necessário",
  fillInTheDetailsAboutTheShippingService: "Preencha os dados sobre o serviço de frete",
  saveTheModifiedInformation: "Salve as informações modificadas",
  afterFillingInAllTheInformation:
    "Depois de preencher todas as informações, clique em salvar para que as informações sejam salvas",
  onThisPageYouWillConfigure:
    "Nessa página você configurará sua loja para poder vender seus produtos",
  finallyOnThisPageYouWillRegisterYourProducts:
    "Por fim nessa página você irá cadastrar seus produtos",
  publicKey: "chave pública",
  privateKey: "chave privada",
  accessWallet: "Acessar carteira",
  disconnectWallet: "Desconectar Carteira",
  notifications: "Notificações",
  noNotifications: "Não há notificações",
  StoresMap: "Mapa de Lojas",
  map: "Mapa",
};

export default userDashboardDictPT;
