const newProductDictIT = {
  newProductcategories: "Categorie",
  subcategories: "Sottocategorie",
  next: "Prossimo",
  previous: "Ritorno",
  clickAndSelect: "Premere un elemento per selezionare, premere di nuovo per deselezionare",
  noSubcategories: "Non ci sono sottocategorie per la categoria selezionata.",
  pleaseSelectCategory: "Seleziona una categoria prima di continuare.",
  pleaseSelectSubcategory: "Seleziona una sottocategoria prima di continuare.",
  addNewProduct: "Aggiungi un nuovo prodotto o servizio alla tua vetrina",
  productInfo: "Informazioni sul prodotto",
  productFields: "Compila i campi con le informazioni sul prodotto",
  promotionalPrice: "Prezzo scontato",
  percentExtracoin: "Percentuale di credito",
  dragImgs: "Trascina fino a 10 immagini qui",
  uploadImages: "Carica Immagini",
  selectProductState: "Seleziona lo stato del prodotto",
  pleaseChooseAtLeastOne: "Scegli almeno un'immagine.",
  youCanOnlyChooseUpTo5: "Puoi scegliere solo fino a 10 immagini.",
  pleaseUploadAtLeastOne: "Scegliere almeno un'immagine.",
  successUploadImage: "Immagine del prodotto caricata con successo.",
  productPreview: "Anteprima del prodotto",
  pressRegisterOrPublish:
    'Premi il pulsante "Registra" per registrarti senza pubblicare (il prodotto sarà visibile solo nella tua lista prodotti).',
  pressRegisterAndPublish:
    'Premi il pulsante "Registra e Pubblica" per registrarti e rendere pubblico il tuo prodotto per tutti.',
  pressRegisterPublishAndNotify:
    'Premi il pulsante "Registra, Pubblica e Notifica" per registrarti, rendere pubblico il tuo prodotto per tutti e notificare gli utenti del sistema sul tuo nuovo prodotto.',
  yourMinimumExtracoinPercentage: "La percentuale minima di Extracredit del tuo piano è",
  buy: "Acquista",
  buyNow: "Acquista ora",
  register: "Registra",
  registerAndPublish: "Registra e Pubblica",
  registerPublishAndNotify: "Registra, Pubblica e Notifica",
  notifyToYourEconomicCircle:
    "La notifica può essere inviata a tutti gli utenti del tuo circolo economico, al costo di",
  notifyAll: "Notifica a tutti gli utenti del sistema, costa",
  insufficientCredits: "Crediti insufficienti",
  unexpectedErrorRegisterProduct: "Errore imprevisto durante la registrazione del prodotto.",
  unexpectedErrorUploadFile: "Errore imprevisto durante il caricamento del file.",
  percentExtracoinExplanationPt1: "Puoi pagare fino al",
  percentExtracoinExplanationPt2: "del prezzo di questo articolo in crediti Extracredit",
  brand: "Marca",
  selectBrand: "Seleziona il marchio del tuo prodotto",
  type: "Tipo",
  selectType: "Seleziona il tipo di prodotto",
  gender: "Modello e Taglia",
  selectGender: "Selezionare genere",
  model: "Modello",
  doYouWantDiscount: "Vuoi dare un prezzo scontato?",
  promotionalPriceMustBeLessThan: "Il prezzo scontato deve essere inferiore al prezzo normale.",
  variationMustHaveAValidPrice: "La variazione deve avere un prezzo valido.",
  sendSeller: "Sono responsabile della spedizione del prodotto",
  addToCart: "Aggiungi al carrello",
  available: "unità disponibili",
  shippingInfo: "Spedizione",
  byBuyer: "Acquirente",
  bySeller: "Venditore",
  productImages: "Immagini del prodotto",
  previewImages: "Immagini di anteprima",
  uploadProductFile: "Carica il file effettivo del tuo prodotto scaricabile:",
  uploadProductSampleFile:
    "Carica un file di anteprima che sarà disponibile pubblicamente nella pagina del prodotto:",
  clickForASample: "Clicca qui per un campione di questo prodotto",
  subheading: "Sottotitolo",
  heading: "Intestazione",
  variationsExplanation:
    "Puoi impostare varianti per questo prodotto nel menu dell'elenco dei prodotti.",
  relatedProducts: "Prodotti Correlati",
  productTechnicalInfo: "Informazioni Tecniche",
  productTechnicalInfoExplanation:
    "Puoi fornire fino a 5 campi di informazioni tecniche sul tuo prodotto",
  fieldName: "Nome del campo",
  fieldValue: "Valore del campo",
  errorWhileRegisteringTechnicalInfo:
    "Errore durante la registrazione delle informazioni tecniche.",
  useShippingService:
    "Utilizzi il servizio di spedizione? (Dovrai fornire le dimensioni dei tuoi prodotti)",
  height: "Altezza",
  width: "Larghezza",
  length: "Lunghezza",
  weight: "Peso",
  shippingUnavailableWarning: "Avviso: servizio merci non disponibile",
  WeDoNotYetHaveShipping:
    "Non abbiamo ancora una quota di spedizione per i mittenti fuori dall'Italia, ovvero i prodotti che necessitano di una quota di spedizione non possono essere spediti dal nostro servizio di spedizione.",
  freightService: "Servizio di spedizione:",
  freightServiceAvaible: "Disponibile",
  freightServiceUnavailable: "Non disponibile",
  service: "Servizio",
  downloadable: "Scaricabile",
  typeOfProduct: "Tipo di prodotto",
  pleaseSelectTypeProduct: "Seleziona il tipo di prodotto.",
  weAreWorkingInThisFeature: "Stiamo lavorando su questa funzionalità.",
  chooseWeightRange: "Scegli l'intervallo di peso del tuo prodotto",
  freight: "Spedizione",
  credits: "crediti",
  notifyCircleExplanationt1:
    "La notifica viene inviata ai soli utenti del tuo circolo economico al costo di",
  notifyCircleExplanationt2: " che ti saranno detratti dal tuo portafoglio di Extrabonus.",
  notifyAllExplanation1:
    "La notifica viene inviata a tutti gli utenti della piattaforma al costo di",
  notifyAllExplanation2: " che ti saranno detratti dal tuo portafoglio di Extrabonus.",
  additionalShippingPriceProduct: "Prezzo di spedizione aggiuntivo del prodotto",
  without: "Senza",
  free2: "Gratis",
  paid: "A pagamento",
  includesTax:
    "Il costo di spedizione dalla nostra piattaforma sarà incluso nel valore del prodotto.",
  youPayFreight: "Sei responsabile della spedizione del prodotto",
  banggoodPaysFreight: "Banggood è responsabile della spedizione del prodotto",
  paidFreight: "Il cliente paga la spedizione",
  lookingTheBestShippingMethod: "Stiamo cercando il miglior metodo di spedizione",
  finalPrice: "Prezzo stimato",
  priceCalculator: "Calcolatore di prezzi",
  or: "o",
  valueCanNotBeNegative: "Il valore non può essere negativo.",
  paidOrWithdrawn: "A pagamento o ritiro in sede",
  paidOrWithdrawnExplain: "Il cliente paga la spedizione o ritira sul posto",
  freightValueExplain:
    "Il costo della spedizione verrà detratto dal valore finale del prodotto per essere restituito gratuitamente al cliente.",
  freightPriceGreaterThanPrice:
    "Il valore del prodotto non può essere inferiore al costo di spedizione.",
  shippingIncluded: "Spedizione Incluse",
  priceCharged: "Prezzo praticato",
  amountToBeReceived: "Importo da ricevere",
  chooseWeightRangeToCalculateShipping: "Scegli l'intervallo di peso per calcolare la spedizione.",
  discounts: "Sconti",
  included: "Incluso (Con la nostra spedizione)",
  includedWithoutSendcloud: "Incluso (Senza la nostra spedizione)",
  theCustomerWillPay: "Il cliente pagherà",
  ifYouWishToReceive: "Se desideri ricevere",
  additionals: "Aggiuntivi",
  thenYouWillHaveToCharge: "Allora dovrai addebitare",
  condition: "Condizione",
  selectCondition: "Seleziona la condizione del prodotto",
  labeled: "Etichettato",
  notLabeled: "Non etichettato",
  excellent: "Eccellente",
  good: "Buono",
  discrete: "Discreto",
  stockGreaterThanZero: "Lo stock deve essere maggiore di zero.",
  minimunValueError:
    "Il volume minimo è di 100 centimetri cubi. Modifica lunghezza, larghezza o altezza.",
  simulateLeastOneShippingValue: "Simula almeno un valore di spedizione.",
  enterZipCodeToSimulateEndCustomer:
    "Inserisci un codice postale per simulare il cliente finale e aggiungere valore al prodotto. Il costo di spedizione verrà detratto dal valore del prodotto al momento dell'acquisto.",
  simulate: "Simulare",
  addValidPostalCode: "Aggiungi un codice postale valido",
  withOurFright: "Con la nostra spedizione",
  withoutOurFright: "Senza la nostra spedizione",
  youHaveExceededThe5PublishedProducts: "Hai superato il limite di 5 prodotti pubblicati.",
  youHaveExceededThe5PublishedProductsExplanation:
    "Per pubblicare più prodotti, devi annullare un prodotto.",
  youCannotPublishProductsYet: "Non puoi pubblicare prodotti ancora.",
  firstCreateYourStore: "Prima crea il tuo negozio.",
  populateCashback: "Compila la percentuale di cashback",
  yourProfile: "Il tuo profilo",
  canOfferACashbackOf: "può offrire un cashback dall' 1% fino al",
  until: "fino a",
  possibleCashbackAmountsIn: "Possibili importi di cashback in",
  wantToGivePercentageBeacuseShare:
    "Vuoi dare un percentuale della tua vendita se il prodotto è stato condiviso da un Promoter",
  specifyThePercentageOfThePersonWhoShares: "Specifica la percentuale per la persona che condivide",
};

export default newProductDictIT;
