const adminBackofficeDictES = {
  adverts: "Anuncios",
  users: "Usuarios",
  orders: "Solicitud",
  paidSubscriptions: "Suscripciones pagas",
  paymentMethod: "Método de pago",
  initialSub: "Registro inicial",
  annualRenewal: "Renovación anual",
  test: "prueba",
  adminDashboard: "Panel Administrativo",
  withdrawRequests: "Solicitudes de retiro",
  requestDate: "Fecha de solicitud",
  processDate: "Fecha de procesamiento",
  processing: "Procesando",
  amountToDeposit: "La cantidad a depositar",
  amountRequested: "Monto requerido",
  processRequest: "Procesa solicitud",
  process: "Proceso",
  processedBy: "Procesado por",
  withdrawProcessedSuccessfully: "¡Solicitud de retiro procesada con éxito!",
  blockOrUnblockUser: "Bloquear/Desbloquear usuario",
  block: "Bloquear",
  unblock: "Desbloquear",
  user: "Usuario",
  processThisRequest: "¿Procesar esta solicitud?",
  userBlockedSuccessfully: "Usuario bloqueado con éxito.",
  userUnblockedSuccessfully: "Usuario desbloqueado con éxito!",
  areYouSureBlockUser: "¿Estás seguro de que quieres bloquear a este usuario?",
  areYouSureUnblockUser: "¿Estás seguro de que quieres desbloquear a este usuario?",
  blocked: "Bloqueado",
  yes: "Sí",
  no: "No",
  category: "Categoría",
  searchCategory: "Buscar Categoría",
  categoriesRegistered: "Categorías Registradas",
  titleCategory: "Título",
  image: "Imagen",
  descriptionPT: "Descripción en portugués",
  descriptionIT: "Descripción en italiano",
  descriptionES: "Descripción en español",
  descriptionEN: "Descripción en inglés",
  registerNewCategory: "registrar nueva categoría",
  addNewCategory: "Agregar nueva categoría",
  newCategory: "Nuevo",
  categoryInfo: "Información de la categoría",
  categoryFields: "Rellena los campos con la información de la categoría.",
  categoryImage: "Imagen de categoría",
  dragImg: "Arrastra tu imagen aquí",
  registerCategory: "Registrar",
  descriptionBrand: "Descripción de la marca",
  brandsRegistered: "Marcas",
  brands: "Marcas",
  addNewBrand: "Agregar nueva marca",
  brandInfo: "Información de la marca",
  brandFields: "Complete el campo con la información de la marca.",
  registerBrand: "Registrarse",
  newBrand: "Nuevo",
  typesCategoriesRegistered: "Tipos Categorías Registradas",
  descriptionType: "Descripción",
  descriptionTypeEN: "Descripción en inglés",
  descriptionTypeES: "Descripción en español",
  descriptionTypeIT: "Descripción en italiano",
  descriptionTypePT: "Descripción en portugués",
  types: "Tipos",
  addNewType: "Agregar nuevos tipos",
  typeInfo: "Información de tipo",
  typeFields: "Rellena los campos con la información del tipo.",
  newType: "Nuevo",
  registerType: "Registrar",
  subcategory: "Subcategorías",
  newsubcategory: "Nuevo",
  subcategoriesRegistered: "Subcategorías registradas",
  descriptionSubcategoryEN: "Descripción en inglés",
  descriptionSubcategoryIT: "Descripción en italiano",
  descriptionSubcategoryPT: "Descripción en portugués",
  descriptionSubcategoryES: "Descripción en español",
  service: "servicio",
  addNewSubcategory: "Agregar nueva subcategoría",
  subcategoryInfo: "Información de subcategoría",
  subcategoryFields: "Rellene los campos con la información de la subcategoría.",
  subcategoryImage: "Imagen de subcategoría",
  registerSubcategory: "Registrar",
  chooseSubcategory: "Elija al menos una subcategoría",
  address: "Dirección",
  userType: "Tipo de usuario",
  chooseUser: "elige un usuario",
  chooseAddress: "elige una dirección",
  UserAddressOnSendCloud: "¿La dirección del usuario estaba registrada en sendcloud?",
  searchUser: "Buscar usuario.",
  userInfo: "informacion del usuario",
  adminBackofficeName: "Nombre",
  hasNoAttribute: "No tiene",
  adminBackofficeUserType: "Tipo de usuario",
  adminBackofficeCountry: "País",
  adminBackofficeAddress: "Dirección",
  adminBackofficeCity: "Ciudad",
  adminBackofficeState: "Estado",
  adminBackofficeDistrict: "Distrito",
  adminBackofficePostalCode: "Código postal",
  adminBackofficeNumber: "Número",
  adminBackofficeComplement: "Complemento",
  adminBackofficeDateCreation: "Fecha de creación",
  adminBackofficePhoneNumber: "Teléfono",
  adminBackofficeOriginRegister: "Registro de origen",
  searchByEmail: "buscar por correo electrónico",
  searchByFirstname: "buscar por nombre",
  searchByPhonenumber: "Buscar por número de teléfono",
  searchByReferralName: "Buscar por referencia",
  searchByBrand: "buscar por marca",
  usersOfSystem: "Usuarios del sistema",
  productsNotPublished: "Productos no publicados",
  archivedProducts: "Productos archivados",
  productsPublished: "Productos publicados",
  salesRevenue: "Ingresos por ventas",
  feeRevenue: "Ingresos por comisiones",
  registeredStores: "Tiendas registradas",
  completedOrders: "Pedidos completados",
  revenueFromUserRegistration: "Ingresos por registro de usuarios",
  productsOfSystem: "Productos del sistema",
  filters: "Filtros",
  search: "Buscar",
  searchByNameSurnameUserAccountBank: "Buscar por nombre, apellido, usuario, cuenta bancaria",
  ordersPlacedWithBangood: "Pedidos realizados con Bangood",
  actions: "Acciones",
  processOrderSuccessfully: "¡Pedido procesado con éxito!",
};

export default adminBackofficeDictES;
